import Button from "../components/button"
import Layout from "../components/layout"
import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import headerImage from "../images/header.png"
import notFoundImage from "../images/404.png"

const NotFoundPage = ({ data }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const titleHeader = `404: Stránka nenalezena (${siteTitle})`
  const ctaUrl = data.site.siteMetadata?.product?.ctaUrl || ``
  const email = data.site.siteMetadata?.email || `Email`
  const organization = data.site.siteMetadata?.organization || `Organization`

  return (
    <Layout email={email} organization={organization}>
      <SEO title="404: Stránka nenalezena" />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          minHeight: "90vh",
          padding: "4rem 1rem",
        }}
      >
        <div
          style={{
            backgroundImage: `url(${headerImage})`,
            position: "absolute",
            top: 0,
            zIndex: -5,
            height: "100vh",
            width: "100vw",
            opacity: 0.5,
          }}
        />
        <h1 style={{ textAlign: "center" }}>{titleHeader}</h1>
        <p style={{ textAlign: "center", maxWidth: 440 }}>
          Jejda Peyda, to je trapas! Tady pro Vás bohužel nic nemáme. Ale náš
          produkt jako takový je skvělý - napište nám pokud o něj máte zájem!
        </p>
        <Button hyperlink={ctaUrl}>ZÍSKAT</Button>
        <div
          style={{
            maxWidth: 756,
            marginTop: 100,
            marginLeft: 10,
            marginRight: 10,
            position: "relative",
          }}
        >
          <img src={notFoundImage} alt="404 Stránka nenalezena" />
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        email
        organization
        product {
          ctaUrl
        }
      }
    }
  }
`
